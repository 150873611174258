@import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(52, 69, 99);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
}

html {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

*,
*:before,
*:after {
  outline: 0;
}

hr {
  border: none;
  border-top: 1px solid #F4F5F7;
}

.notification-container {
  padding-bottom: 12px;
}

.notification {
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.247059) 0px 4px 8px -2px, rgba(9, 30, 66, 0.309804) 0px 0px 1px 0px;
}

.notification-success {
  background-color: #30AA73;
}

.page-layout--wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.page-layout--wrapper > * {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.droppable-board {
  flex-grow: 999999 !important;
  flex-shrink: 1 !important;
}

.ak-button--fullwidth {
  width: 100%;
}

.ak-button--fullwidth > span {
  width: 100%;
}

.ak-button-group--wrapper {
  margin-top: 1em;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
}

.ak-button-group--wrapper > div {
  width: 100%;
  margin: 0 !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
}

.ak-button-group--wrapper > div > div:first-child{
  margin-left: 0;
}

.ak-button-group--wrapper > div > div:last-child{
  margin-right: 0;
}

.dropdown-item--decline > span > span {
  color: rgb(23,43,77);
}

.board-lane {
  background-color: #F4F5F7;
  height:100%;
  width:100%;
}

.board-lane.board-lane--isholiday {
  background-color: white;
}


.board-lane.board-lane--isholiday .board-lanestrip:before { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(255, 255, 255, 1) 0%,rgb(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}

.board-lane.board-lane--isholiday .board-lanestrip:after { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(255, 255, 255, 0) 0%,rgb(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.board-lane.board-lane--isdraggingover,
.board-lane.board-lane--isdraggingover > div:last-child > div {
  background-color: #DADDE2;
}

.board-lane.board-lane--isdraggingover .board-lanestrip:before { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(218, 221, 226, 1) 0%,rgb(218, 221, 226, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadde2', endColorstr='#00dadde2',GradientType=0 ); /* IE6-9 */
}

.board-lane.board-lane--isdraggingover .board-lanestrip:after { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(218, 221, 226, 0) 0%,rgb(218, 221, 226, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dadde2', endColorstr='#dadde2',GradientType=0 ); /* IE6-9 */
}

.select__control + div * {
  z-index: 10;
}

.sidebar-filter-container .select__control {
  overflow: hidden;
}

.sidebar-filter-container .select__value-container {
  padding: 0;
}

.sidebar-filter-container .select__indicators .select__indicator {
  padding: 0;
  padding-right: 3px;
}

.sidebar-filter-container .select__placeholder {
  padding-left: 5px;
}

.board-sum {
  margin-left: 10px;
  margin-right: 10px;
}

.board-sum--block {
  margin-bottom: 15px;
}

.button-wide {
  flex-grow: 1;
  margin-left: 10px;
}

.button-spaced {
  margin-left: 10px;
}

.button-wide > span {
  width: 100%;
}

.react-datepicker-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 40px;
}

.react-datepicker__input-container {
  align-items: center;
  background-color: #FAFBFC;
  border-color: transparent;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #091E42;
  display: flex;
  flex: 1 0 auto;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.4285714285714286;
  max-width: 100%;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  word-wrap: break-word;
  border-width: 2px;
  padding: 6px 6px;
}

.react-datepicker__input-container:hover {
  background-color: #EBECF0
}

.react-datepicker__input-container input {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  width: 100%;
}

.wom-export-button span {
  margin-left: 10px;
  margin-right: 5px;
}

.wom-export-button > div:first-child {
  position: relative;
  top: -1px;
}

.wom-button-spinner span.text {
  margin-left: 20px;
}

.wom-fine-print {
  font-size: 12px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  list-style-position: initial;
  list-style-image: initial;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  color: rgb(222, 235, 255);
}

.wom-fine-print > li + li::before {
  content: "•";
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 0px;
  margin-left: 8px;
}

.wom-fine-print a {
  color: rgb(222, 235, 255);
  text-decoration: none;
}

.wom-fine-print a:hover {
  text-decoration: underline;
}

.wom-material-groups span:first-child {
  margin-left: 0;
}

.wom-material-groups span {
  margin-left: 3px;
}

.wom-material-groups.wom-material-groups--mini span {
  font-size: 0.7em;
  display: block;
  margin-left: 0;
  background-color: #F4F5F7;
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 1px 3px;
}

.wom-material-groups.wom-material-groups--mini span:after {
  content: "";
}

.wom-material-groups span:after {
  content: ",";
}

.wom-material-groups span:last-child:after {
  content: "";
}

.wom-table-toggle input + div {
  height: 28px;
  width: 48px;
}

.wom-document-item > div {
  font-size: 0.7em;
}

.wom-document-item > div > span[class^="Icon__IconWrapper"] {
  display: block;
}
